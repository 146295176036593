import React, { useRef, useEffect } from "react";

const Confirmation = () => {
  // const videoRef = useRef(undefined);
  // useEffect(() => {
  //   videoRef.current.defaultMuted = true;
  // });
  return (
    <div
      // className="bg-dots"
      style={{
        marginTop: -15,
      }}
    >
      {/* <div className="principito-pseudo"></div> */}
      <div
        className="party-container"
        style={{ padding: "15px 30px 20px 30px" }}
      >
        <span style={{ marginTop: 12 }}>
          <i>
            "Me pregunto si las estrellas se iluminan con el fin de que algún
            día, cada uno pueda encontrar la suya"
          </i>
        </span>
        <span
          style={{
            fontSize: 12,
          }}
        >
          El principito
        </span>

        <div style={{ margin: "25px 0 17px 0" }}>
          <a
            target="_blank"
            rel="noreferrer"
            className="main-btn asistencia"
            style={{ marginTop: 10, marginBottom: 10 }}
            href="https://api.whatsapp.com/send?phone=5492344469011&text="
          >
            CONFIRMAR ASISTENCIA
          </a>
        </div>
      </div>
      <div className="reserve-text-container" style={{ paddingBottom: -10 }}>
        <span style={{ fontSize: 12, lineHeight: 0.5 }}>
          Por favor, confirmar antes del 14 de diciembre.
        </span>
      </div>
      {/* <div className="video-container">
        <video ref={videoRef} loop autoPlay muted playsInline>
          <source src={Video} type="video/mp4" />
        </video>
        <div
          style={{
            textAlign: "center",
            width: "100%",
            position: "absolute",
            top: "47%",
          }}
        >
          <a
            // style={{ position: "absolute", left: 120 }}
            target="_blank"
            rel="noreferrer"
            className="outline-btn"
            href="https://api.whatsapp.com/send?phone=5491155775902&text=%C2%A1Hola!%20Les%20escribo%20para%20festejar%20el%20casamiento%20de%20Ceci%20y%20Mati%20con%20ustedes.%20Me%20gustar%C3%ADa%20saber%20qu%C3%A9%20opciones%20de%20alojamiento%20tienen.%20%C2%A1Gracias!%20"
          >
            RESERVAR
          </a>
        </div>
      </div> */}
    </div>
  );
};

export default Confirmation;
