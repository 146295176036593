import React from "react";
import { Carousel } from "react-bootstrap";
const Gallery = () => {
  return (
    <div className="">
      {" "}
      {/* <hr
        style={{
          color: "rgba(85, 85, 85, 0.3)",
        }}
      /> */}
      <div
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          padding: "0px 30px 10px 30px",
        }}
      >
        <span
          style={{
            marginBottom: -10,
          }}
          className="title mai"
        >
          Galería
        </span>

        {/* <span className="save-date-text">
          *Aca puede ir un texto o podemos cambiar el titulo, como deseen*
        </span> */}

        {/* <hr /> */}
        {/* <span className="title">¡Te esperamos!</span> */}
        {/* <span className="save-date-text"> */}
        {/* para que disfrutemos juntos de nuestro casamiento */}
        {/* </span> */}
      </div>
      <Carousel
        touch
        interval={null}
        prevIcon={
          <span aria-hidden="true" className="prev-control">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </span>
        }
        nextIcon={
          <svg
            className="next-control"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        }
      >
        <Carousel.Item>
          <img
            className="d-block w-100 img-gallery "
            src="1-2dias.jpg"
            alt="Mis primeros 2 días"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 img-gallery"
            src="2-1mes.jpg"
            alt="Mi primer mes"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 img-gallery"
            src="3-2meses.jpg"
            alt="A mis 2 meses"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 img-gallery show-top "
            src="4-3meses.jpg"
            alt="A mis 3 meses"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 img-gallery family show-top"
            src="5-4meses.jpg"
            alt="Mis 4 meses"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 img-gallery change-position "
            src="6-5meses.jpg"
            alt="Mis 5 meses"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 img-gallery change-position "
            src="7-6meses.jpg"
            alt="A mis 6 meses"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 img-gallery change-position "
            src="8-7meses.jpg"
            alt="A mis 7 meses"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 img-gallery change-position "
            src="9-8meses.jpg"
            alt="Mis 8 meses"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 img-gallery change-position "
            src="10-9meses.jpg"
            alt="Mis 9 meses"
            style={{
              objectFit: "cover",
              objectPosition: "80% 80%",
            }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 img-gallery change-position "
            src="11-10meses.jpg"
            alt="Mis 10 meses"
            style={{
              objectFit: "cover",
              objectPosition: "10% 10%",
            }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 img-gallery change-position "
            src="12-11meses.jpg"
            alt="Mis 11 meses"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 img-gallery change-position "
            src="13-12meses.jpg"
            alt="¡Mi primer año!"
            style={{
              objectFit: "cover",
              objectPosition: "10% 10%",
            }}
          />
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Gallery;
