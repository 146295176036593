import React, { useEffect, useState } from "react";

const CountDown = () => {
  const [countDown, setCountDown] = useState({
    days: 0,
    hours: 0,
    min: 0,
    sec: 0,
  });

  const calculateCountdown = (endDate) => {
    let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;

    // clear countdown when date is reached
    if (diff <= 0) return false;

    const timeLeft = {
      years: 0,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
      millisec: 0,
    };

    // calculate time difference between now and expected date
    if (diff >= 365.25 * 86400) {
      // 365.25 * 24 * 60 * 60
      timeLeft.years = Math.floor(diff / (365.25 * 86400));
      diff -= timeLeft.years * 365.25 * 86400;
    }
    if (diff >= 86400) {
      // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400);
      diff -= timeLeft.days * 86400;
    }
    if (diff >= 3600) {
      // 60 * 60
      timeLeft.hours = Math.floor(diff / 3600);
      diff -= timeLeft.hours * 3600;
    }
    if (diff >= 60) {
      timeLeft.min = Math.floor(diff / 60);
      diff -= timeLeft.min * 60;
    }
    timeLeft.sec = diff;

    return timeLeft;
  };

  const addLeadingZeros = (value) => {
    value = String(value);
    while (value.length < 2) {
      value = `0${value}`;
    }
    return value;
  };

  // Here a date has been assigned
  // while creating Date object
  let dateobj = new Date("July 15, 2023 21:30:00");

  const date = "2023-12-16T15:00:00.000Z";
  useEffect(() => {
    const interval = setInterval(() => {
      const newDate = calculateCountdown(date);
      if (newDate) {
        setCountDown(newDate);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [date]);

  return (
    <>
      <div data-fecha="i" className="countdown-container" id="contador">
        <div>{addLeadingZeros(countDown.days)}</div>
        <div>{addLeadingZeros(countDown.hours)}</div>
        <div>{addLeadingZeros(countDown.min)}</div>
        <div>{addLeadingZeros(countDown.sec)}</div>
      </div>
    </>
  );
};

export default CountDown;
