import React from "react";

const SaveDate = () => {
  return (
    <div id="save-date" className="save-date-container">
      <span className="title">Festejemos juntos</span>
      <span className="save-date-text">
        Te invito a festejar mi <b>primer añito</b>
        &nbsp;el
        <b>&nbsp;sábado 16 de diciembre</b> a partir de las <b>12h</b>.
      </span>
      <span className="save-date-text"></span>
    </div>
  );
};

export default SaveDate;
