import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import Cover from "./components/Cover";
import Circles from "./components/Circles";
import SaveDate from "./components/SaveDate";
import CountDown from "./components/CountDown";
import SecondText from "./components/SecondText";
import Party from "./components/Party";
import Gallery from "./components/Gallery";
import Confirmation from "./components/Confirmation";
import Spotify from "./components/Spotify";
import MakeUp from "./components/MakeUp";
import Drescode from "./components/Dresscode";
function App() {
  return (
    <div className="App">
      <Cover />
      {/* <Circles /> */}
      <SaveDate />
      <CountDown />
      {/* <SecondText /> */}
      <Party />
      {/* <Drescode /> */}
      <Gallery />
      <Confirmation />
      {/* <Spotify /> */}
      {/* <MakeUp /> */}
    </div>
  );
}

export default App;
