import React from "react";
import Moon from "../images/moon2.png";
const Party = () => {
  return (
    <div
      style={{
        marginTop: -30,
      }}
      className="party-container"
    >
      <img className="moon" src={Moon} width={90} />
      <span className="title">¿Cómo llegar?</span>
      {/* <span className="resort-subtitle">AOMA</span> */}
      <span className="save-date-text">
        Rivadavia y Juan J. Suquia, Saladillo.
      </span>
      <div style={{ marginTop: 20, marginBottom: 15, width: "100%" }}>
        <a
          target="_blank"
          rel="noreferrer"
          className="main-btn"
          href="https://maps.app.goo.gl/YWMerVJAs7eq82E58"
        >
          VER MAPA
        </a>
      </div>
    </div>
  );
};

export default Party;
